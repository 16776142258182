<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-30 -519) translate(30 519)">
            <g>
              <g>
                <circle cx="20" cy="20" r="20" :fill="iconColor()[1]" />
                <rect
                  width="16.1"
                  height="11.3"
                  x="12.75"
                  y="13.55"
                  :stroke="iconColor()[0]"
                  stroke-width="1.5"
                  rx="1"
                />
                <path
                  :stroke="iconColor()[0]"
                  stroke-width="1.5"
                  d="M26.4 28h-16c-.442 0-.8-.358-.8-.8v-5.579V16"
                />
                <path
                  :fill="iconColor()[0]"
                  d="M15.2 21.6L26.4 21.6 26.4 22.8 15.2 22.8z"
                />
                <path
                  :stroke="iconColor()[0]"
                  stroke-width="1.3"
                  d="M19.85 15.85H21.75V17.75H19.85z"
                />
                <path
                  :fill="iconColor()[0]"
                  d="M15.2 19.36L26.4 19.36 26.4 20.56 15.2 20.56z"
                />
              </g>
            </g>
            <rect
              width="16.1"
              height="11.3"
              x="12.75"
              y="13.55"
              :stroke="iconColor()[0]"
              stroke-width="1.5"
              rx="1"
            />
          </g>
          <g :fill="iconColor()[1]">
            <g>
              <g transform="translate(-30 -519) translate(30 519)">
                <circle cx="20" cy="20" r="20" />
              </g>
            </g>
          </g>
          <g :fill="iconColor()[0]" fill-rule="nonzero">
            <g>
              <path
                :stroke="iconColor()[0]"
                stroke-width=".5"
                d="M0 14.137H19.608V15.235000000000001H0z"
                transform="translate(-30 -519) translate(30 519) translate(10.4 11.2) translate(0 .392)"
              />
              <path
                d="M2.098 11.255c-.49 0-.941-.177-1.294-.51-.294-.274-.47-.647-.569-1.157l-.137-.745 1.47-.255.138.745c.04.196.098.294.118.314.078.059.176.098.294.098.353-.02.686-.216 1-.47-.236-.255-.432-.53-.589-.824-1.098-2-1.117-4.49-.039-6.51.353-.647.745-1.117 1.216-1.431.667-.451 1.49-.55 2.157-.275.608.255 1.098.804 1.313 1.53.177.588.157 1.196.118 1.686-.137 1.608-.647 3.235-1.47 4.667-.138.255-.334.568-.53.902.412.156.843.176 1.255-.02.353-.157.608-.451.941-.882.353-.432.55-.824.647-1.255.079-.412.47-.667.883-.588.294.058.529.294.588.588.117.294.745 1.47.902 1.686l.059.098c.353.49.549.726.764.667.353-.079.667-.47.98-.922.334-.47.589-1.02.746-1.568.078-.295.333-.53.647-.55.314-.039.608.138.745.412l.412.824c.372.745.706 1.333 1.196 1.627.314.196.725.255 1.078.157.236-.059.314-.176.608-.667l.373-.647 1.294.765-.373.647c-.314.53-.666 1.137-1.51 1.353-.745.196-1.549.079-2.215-.314-.687-.392-1.118-1.039-1.451-1.627-.098.176-.196.333-.314.49-.373.51-.941 1.294-1.882 1.51-1.196.274-1.883-.686-2.294-1.235l-.098-.157s-.197-.294-.412-.667c-.079.098-.157.196-.236.314-.352.431-.784.96-1.47 1.294-.941.431-1.96.314-2.843-.137-.569.568-1.255 1-2.118 1.039h-.098zm2.98-9.686c-.176 0-.372.058-.529.176-.255.177-.49.47-.725.902-.824 1.55-.804 3.55.039 5.098.059.118.137.235.235.353.177-.274.314-.53.431-.725.706-1.255 1.157-2.648 1.275-4.06.04-.352.059-.764-.059-1.117-.078-.274-.255-.49-.45-.588-.06-.02-.138-.04-.217-.04z"
                transform="translate(-30 -519) translate(30 519) translate(10.4 11.2) translate(0 .392)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['selected'],
  methods: {
    iconColor() {
      return this.selected ? ['#009fb1', '#DBEDEF'] : ['#373c43', '#F8F8F8'];
    },
  },
};
</script>
