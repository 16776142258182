<template>
  <div class="template-editor">
    <Header />
    <div class="body">
      <TabsSelector />
      <div class="tabs">
        <component :is="selectedTab" />
      </div>
      <Canvas :editable="!isPrinting" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Attributes from './components/Tabs/Attributes';
import Backgrounds from './components/Tabs/Backgrounds';
import Canvas from './components/Canvas/Canvas';
import Footer from './components/Footer';
import Header from './components/Header';
import Images from './components/Tabs/Images';
import Signatures from './components/Tabs/Signatures';
import Templates from './components/Tabs/Templates';
import TabsSelector from './components/TabsSelector';

export default {
  components: {
    Attributes,
    Backgrounds,
    Canvas,
    Footer,
    Header,
    Images,
    Signatures,
    Templates,
    TabsSelector,
  },
  data() {
    return {
      initialColor: '#ffffff',
    };
  },
  methods: {
    selectTab(tab) {
      this.$store.dispatch('selectTab', tab);
    },
    colorChange() {
      if (this.layoutBackgroundColor !== this.initialColor) {
        this.initialColor = this.layoutBackgroundColor;
        this.selectTab('Attributes');
      }
    },
  },
  computed: {
    isPrinting() {
      return this.$store.getters.isPrinting;
    },
    selectedTab() {
      return this.$store.getters.selectedTab;
    },
    layoutBackgroundColor() {
      return this.$store.getters.layoutBackgroundColor;
    },
  },
};
</script>

<style>
.template-editor * {
  color: #373c43;
  overflow-y: hidden !important;
  outline: none;
}

.template-editor ::-webkit-scrollbar {
  display: none;
}

.template-editor {
  position: fixed;
  z-index: 7;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.body {
  display: flex;
  flex-grow: 1;
}

.tabs {
  min-width: 18rem;
  max-width: 18rem;
  overflow-y: scroll !important;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  padding-top: 1rem;
}
</style>
