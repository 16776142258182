<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-30 -124) translate(30 124)">
            <g>
              <g>
                <circle cx="20" cy="20" r="20" :fill="iconColor()[1]" />
                <rect
                  width="16.1"
                  height="11.3"
                  x="12.75"
                  y="13.55"
                  :stroke="iconColor()[0]"
                  stroke-width="1.5"
                  rx="1"
                />
                <path
                  :stroke="iconColor()[0]"
                  stroke-width="1.5"
                  d="M26.4 28h-16c-.442 0-.8-.358-.8-.8v-5.579V16"
                />
                <path
                  :fill="iconColor()[0]"
                  d="M15.2 21.6L26.4 21.6 26.4 22.8 15.2 22.8z"
                />
                <path
                  :stroke="iconColor()[0]"
                  stroke-width="1.3"
                  d="M19.85 15.85H21.75V17.75H19.85z"
                />
                <path
                  :fill="iconColor()[0]"
                  d="M15.2 19.36L26.4 19.36 26.4 20.56 15.2 20.56z"
                />
              </g>
            </g>
            <rect
              width="16.1"
              height="11.3"
              x="12.75"
              y="13.55"
              :stroke="iconColor()[0]"
              stroke-width="1.5"
              rx="1"
            />
          </g>
          <g transform="translate(-30 -124) translate(30 124)">
            <g>
              <g>
                <circle cx="20" cy="20" r="20" :fill="iconColor()[1]" />
                <rect
                  width="16.1"
                  height="11.3"
                  x="12.75"
                  y="13.55"
                  :stroke="iconColor()[0]"
                  stroke-width="1.5"
                  rx="1"
                />
                <path
                  :stroke="iconColor()[0]"
                  stroke-width="1.5"
                  d="M26.4 28h-16c-.442 0-.8-.358-.8-.8v-5.579V16"
                />
                <path
                  :fill="iconColor()[0]"
                  d="M15.2 21.6L26.4 21.6 26.4 22.8 15.2 22.8z"
                />
                <path
                  :stroke="iconColor()[0]"
                  stroke-width="1.3"
                  d="M19.85 15.85H21.75V17.75H19.85z"
                />
                <path
                  :fill="iconColor()[0]"
                  d="M15.2 19.36L26.4 19.36 26.4 20.56 15.2 20.56z"
                />
              </g>
            </g>
            <rect
              width="16.1"
              height="11.3"
              x="12.75"
              y="13.55"
              :stroke="iconColor()[0]"
              stroke-width="1.5"
              rx="1"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['selected'],
  methods: {
    iconColor() {
      return this.selected ? ['#009fb1', '#DBEDEF'] : ['#373c43', '#F8F8F8'];
    },
  },
};
</script>
