import { render, staticRenderFns } from "./Attributes.vue?vue&type=template&id=ee23f322&scoped=true"
import script from "./Attributes.vue?vue&type=script&lang=js"
export * from "./Attributes.vue?vue&type=script&lang=js"
import style0 from "./Attributes.vue?vue&type=style&index=0&id=ee23f322&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee23f322",
  null
  
)

export default component.exports