<template>
  <div class="tabs-selector">
    <button v-for="tab in tabs" :key="tab.value" @click="selectTab(tab)">
      <div class="tab" :class="{ selected: isSelectedTab(tab) }">
        <component
          :is="'Icon' + tab"
          :selected="isSelectedTab(tab)"
        ></component>
        {{ $t('tabs.' + tab) }}
      </div>
    </button>
  </div>
</template>

<script>
import IconTemplates from '../assets/icon-templates';
import IconAttributes from '../assets/icon-attributes';
import IconImages from '../assets/icon-images';
import IconSignatures from '../assets/icon-signatures';
import IconBackgrounds from '../assets/icon-backgrounds';

export default {
  components: {
    IconTemplates,
    IconAttributes,
    IconImages,
    IconSignatures,
    IconBackgrounds,
  },
  data() {
    return {
      tabs: ['Templates', 'Backgrounds', 'Attributes', 'Images', 'Signatures'],
    };
  },
  methods: {
    selectTab(tab) {
      if (this.currentLayout) {
        this.$store.dispatch('selectTab', tab);
      }
    },
    isSelectedTab(tab) {
      return tab === this.selectedTab;
    },
  },
  computed: {
    selectedTab() {
      return this.$store.getters.selectedTab;
    },
    currentLayout() {
      return this.$store.getters.currentLayout;
    },
  },
};
</script>

<style scoped>
.tabs-selector {
  display: flex;
  flex-direction: column;
  min-width: 6rem;
  border-right: 1px solid #d8d8d8;
  background-color: #ececec;
  overflow-y: scroll !important;
}

/* Hide scrollbar for Chrome, Safari and Opera only*/
.tabs-selector::-webkit-scrollbar {
  display: none;
}

button {
  border-bottom: 1px solid #d8d8d8;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
}

.tab {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 0.5rem 0;
  font-size: 12px;
  font-weight: 600;
}

.selected {
  background-color: #f8f8f8;
  color: #009fb1;
}
</style>
