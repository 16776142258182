<template>
  <svg viewBox="0 0 100 100" style="overflow: visible !important">
    <circle
      cx="50%"
      cy="50%"
      r="50%"
      stroke-width="0.5"
      :stroke="highlighted()"
      :fill="background"
    />
    <text x="33" y="53" fill="#e2e2e2" font-size="0.6rem" font-weight="normal">
      Badge
    </text>
  </svg>
</template>

<script>
export default {
  props: ['highlight', 'background'],
  methods: {
    highlighted() {
      return this.highlight ? '#009fb1' : '#e2e2e2';
    },
  },
};
</script>
