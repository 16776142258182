<template>
  <div
    style="
      overflow: scroll !important;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      min-height: 40rem;
    "
  >
    <div>{{ $t('backgrounds.title') }}</div>
    <input
      type="file"
      ref="uploadTemplateFrontDesign"
      style="display: none"
      accept="image/*"
      @change="newTemplateDesign($event, 'front')"
    />
    <button @click="uploadTemplateDesign('front')" style="margin-top: 1.5rem">
      {{
        currentLayout === 'Badge'
          ? $t('templates.buttonBadge')
          : $t('templates.buttonFront')
      }}
    </button>
    <input
      type="file"
      ref="uploadTemplateBackDesign"
      style="display: none"
      accept="image/*"
      @change="newTemplateDesign($event, 'back')"
    />
    <button v-if="currentLayout === 'A4'" @click="uploadTemplateDesign('back')">
      {{ $t('templates.buttonBack') }}
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    uploadTemplateDesign(templateSide) {
      if (templateSide === 'front') {
        this.$refs.uploadTemplateFrontDesign.click();
      } else {
        this.$refs.uploadTemplateBackDesign.click();
      }
    },
    newTemplateDesign(event, templateSide) {
      const fileReader = new FileReader();
      const templatesComponent = this;
      fileReader.onload = function () {
        var template = templatesComponent.template;
        if (templateSide === 'front') {
          template.idFront = 0;
          template.backgroundFront = fileReader.result;
        } else {
          if (templatesComponent.backSide === false) {
            templatesComponent.enableBackSide();
          }
          template.backgroundBack = fileReader.result;
        }
        templatesComponent.$store.dispatch('setTemplate', template);
        event.target.value = '';
      };
      fileReader.readAsDataURL(event.target.files[0]);
    },
    setTemplate(preset, templateSide) {
      this.$store.dispatch('setActiveComponent', '');
      var template = {
        name: '',
        repeatedAttributes: false,
        idFront: preset.id,
        components: JSON.parse(JSON.stringify(preset.components)),
        backgroundFront: '',
        backgroundBack: '',
      };
      if (templateSide === 'front') {
        template.backgroundFront = preset.background;
      } else {
        template.backgroundBack = preset.background;
      }
      this.$store.dispatch('setTemplate', template);
    },
    isTemplate(preset) {
      return preset.id === this.template.idFront;
    },
    enableBackSide() {
      this.$store.dispatch('enableBackSide', true);
    },
    selectTab(tab) {
      if (this.currentLayout) {
        this.$store.dispatch('selectTab', tab);
      }
    },
  },
  computed: {
    template() {
      return this.$store.getters.template;
    },
    backSide() {
      return this.$store.getters.backSide;
    },
    activeComponent() {
      return this.$store.getters.activeComponent;
    },
    renderedDimensions() {
      return this.$store.getters.renderedDimensions;
    },
    currentLayout() {
      return this.$store.getters.currentLayout;
    },
  },
};
</script>

<style scoped>
hr {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border: none;
  background-color: #d8d8d8;
  height: 0.05rem;
}

img {
  border: 2px solid transparent;
  max-height: 100%; /* needed because for some reason images don't fit the div in the backside */
  max-width: 100%;
  border: 2px solid transparent;
}

.selected {
  border: 2px solid #009fb1;
}

button {
  margin-top: 1rem;
  padding: 0.5rem;
  color: #009fb1;
  border: 1px solid #009fb1;
  border-radius: 0.3rem;
}

.templates {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  font-size: 15px;
  font-weight: 500;
}

/* Hide scrollbar for Chrome, Safari and Opera only*/
.templates::-webkit-scrollbar {
  display: none;
}

.instructions {
  padding-top: 0;
  margin-top: 0.5rem;
  font-size: 12px;
}
</style>
