<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-30 -223) translate(30 223)">
            <g>
              <g>
                <circle cx="20" cy="20" r="20" :fill="iconColor()[1]" />
                <rect
                  width="16.1"
                  height="11.3"
                  x="12.75"
                  y="13.55"
                  :stroke="iconColor[0]"
                  stroke-width="1.5"
                  rx="1"
                />
                <path
                  :stroke="iconColor()[0]"
                  stroke-width="1.5"
                  d="M26.4 28h-16c-.442 0-.8-.358-.8-.8v-5.579V16"
                />
                <path
                  :fill="iconColor()[0]"
                  d="M15.2 21.6L26.4 21.6 26.4 22.8 15.2 22.8z"
                />
                <path
                  :stroke="iconColor()[0]"
                  stroke-width="1.3"
                  d="M19.85 15.85H21.75V17.75H19.85z"
                />
                <path
                  :fill="iconColor()[0]"
                  d="M15.2 19.36L26.4 19.36 26.4 20.56 15.2 20.56z"
                />
              </g>
            </g>
            <rect
              width="16.1"
              height="11.3"
              x="12.75"
              y="13.55"
              :stroke="iconColor()[0]"
              stroke-width="1.5"
              rx="1"
            />
          </g>
          <g :fill="iconColor()[1]">
            <g>
              <g transform="translate(-30 -223) translate(30 223)">
                <circle cx="20" cy="20" r="20" />
              </g>
            </g>
          </g>
          <g :fill="iconColor()[0]" fill-rule="nonzero">
            <g>
              <path
                d="M1.762 0C.796 0 0 .794 0 1.758 0 2.509.485 3.152 1.156 3.4v10.796C.486 14.446 0 15.09 0 15.842c0 .964.796 1.758 1.762 1.758.759 0 1.406-.494 1.652-1.172h10.772c.246.678.893 1.172 1.652 1.172.966 0 1.762-.794 1.762-1.758 0-.758-.494-1.406-1.175-1.65V3.428c.68-.244 1.175-.89 1.175-1.647 0-.964-.796-1.758-1.762-1.758-.76 0-1.407.493-1.652 1.172H3.42C3.182.505 2.53 0 1.762 0zm.059 1.214c.342 0 .607.264.607.607 0 .342-.265.607-.607.607-.343 0-.607-.265-.607-.607 0-.343.264-.607.607-.607zm13.958 0c.343 0 .607.264.607.607 0 .342-.264.607-.607.607-.342 0-.607-.265-.607-.607 0-.343.265-.607.607-.607zM3.49 2.428h10.633c.177.487.562.874 1.05 1.05V14.12c-.488.176-.874.564-1.05 1.052H3.496c-.178-.494-.571-.886-1.068-1.059V3.462c.489-.17.879-.551 1.06-1.034zm1.47 2.379v.004l-.007 2.603h1.106l.005-1.548h2.22L8.27 12.19l-1.108-.004-.004 1.057 3.33.01.005-1.056-1.117-.005.013-6.327h2.197l-.002 1.546h1.106l.006-2.605H4.96zM1.82 15.172c.342 0 .607.265.607.607 0 .343-.265.607-.607.607-.343 0-.607-.264-.607-.607 0-.342.264-.607.607-.607zm13.958 0c.343 0 .607.265.607.607 0 .343-.264.607-.607.607-.342 0-.607-.264-.607-.607 0-.342.265-.607.607-.607z"
                transform="translate(-30 -223) translate(30 223) translate(11.2 11.2)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['selected'],
  methods: {
    iconColor() {
      return this.selected ? ['#009fb1', '#DBEDEF'] : ['#373c43', '#F8F8F8'];
    },
  },
};
</script>
