<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-30 -421) translate(30 421)">
            <g>
              <g>
                <circle cx="20" cy="20" r="20" :fill="iconColor()[1]" />
                <rect
                  width="16.1"
                  height="11.3"
                  x="12.75"
                  y="13.55"
                  :stroke="iconColor()[0]"
                  stroke-width="1.5"
                  rx="1"
                />
                <path
                  :stroke="iconColor()[0]"
                  stroke-width="1.5"
                  d="M26.4 28h-16c-.442 0-.8-.358-.8-.8v-5.579V16"
                />
                <path
                  :fill="iconColor()[0]"
                  d="M15.2 21.6L26.4 21.6 26.4 22.8 15.2 22.8z"
                />
                <path
                  :stroke="iconColor()[0]"
                  stroke-width="1.3"
                  d="M19.85 15.85H21.75V17.75H19.85z"
                />
                <path
                  :fill="iconColor()[0]"
                  d="M15.2 19.36L26.4 19.36 26.4 20.56 15.2 20.56z"
                />
              </g>
            </g>
            <rect
              width="16.1"
              height="11.3"
              x="12.75"
              y="13.55"
              :stroke="iconColor()[0]"
              stroke-width="1.5"
              rx="1"
            />
          </g>
          <g :fill="iconColor()[1]">
            <g>
              <g transform="translate(-30 -421) translate(30 421)">
                <circle cx="20" cy="20" r="20" />
              </g>
            </g>
          </g>
          <g :fill="iconColor()[0]" fill-rule="nonzero">
            <g>
              <path
                d="M1.46.262C.663.262 0 .936 0 1.75V12.37c0 .813.662 1.487 1.46 1.487h16.28c.798 0 1.46-.674 1.46-1.487V1.75c0-.814-.662-1.488-1.46-1.488H1.46zm0 1.275h16.28c.126 0 .208.083.208.212v8.338l-3.294-3.671c-.127-.142-.32-.22-.508-.206-.155.011-.305.084-.411.2l-2.83 3.06L6.73 4.722c-.126-.144-.319-.225-.508-.212-.157.01-.31.082-.418.199L1.252 9.688V1.75c0-.129.082-.212.209-.212zm9.6.425c-1.029 0-1.877.863-1.877 1.911 0 1.049.848 1.912 1.878 1.912 1.03 0 1.878-.863 1.878-1.912 0-1.048-.848-1.911-1.878-1.911zm0 1.274c.354 0 .627.278.627.637 0 .36-.273.638-.626.638s-.626-.278-.626-.638.273-.637.626-.637zM6.249 6.09l5.706 6.493H1.461c-.127 0-.209-.084-.209-.213v-.816L6.248 6.09zm7.937 1.693l3.763 4.196v.391c0 .129-.082.213-.209.213h-4.102l-1.898-2.158 2.446-2.642z"
                transform="translate(-30 -421) translate(30 421) translate(10.4 12.8) translate(0 .462)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['selected'],
  methods: {
    iconColor() {
      return this.selected ? ['#009fb1', '#DBEDEF'] : ['#373c43', '#F8F8F8'];
    },
  },
};
</script>
