<template>
  <div style="overflow: scroll !important; padding: 1.5rem; min-height: 40rem">
    <!-- Tab Title -->
    <div>{{ $t('templates.title') }}</div>
    <!-- Layouts -->
    <!-- ADD: Custom ratio layout -->
    <div
      v-for="layout in layouts"
      :key="layout"
      @click="changeLayout(layout)"
      style="overflow: visible !important"
    >
      <div class="layout-name">{{ layout }}</div>
      <component
        :is="layout"
        :highlight="isCurrentLayout(layout)"
        background="#ffffff"
        style="overflow: visible !important"
      ></component>
    </div>
  </div>
</template>

<script>
import A4 from '../../assets/Layouts/rectangle-a4.vue';
import Badge from '../../assets/Layouts/badge-circular.vue';
import NFT from '../../assets/Layouts/rectangle-nft.vue';
import { mapGetters } from 'vuex';
import { CardDesign } from '../../../../consts/designPresets';
const API_URL_IMAGES = process.env.VUE_APP_API_URL_IMAGES;
export default {
  components: {
    A4,
    Badge,
    NFT,
  },
  data() {
    return {
      customTemplate: { 'wallid.io': true },
      API_URL_IMAGES,
    };
  },
  methods: {
    changeLayout(layout) {
      this.debug('Change Layout', layout);
      this.$store.commit('changeLayout', layout);
      this.$store.dispatch('enableBackSide', false);
      this.$store.dispatch('setActiveComponent', '');
      this.$store.commit('updateLayoutBackgroundColor', '#29969E');
      this.$store.commit('setComponents', []);
      if (layout !== 'NFT') {
        this.$store.commit('setComponents', [
          {
            id: 0,
            type: 'qrcode',
            templateSide: 'front',
            x: 94.4,
            y: 92.4,
            isDraggable: false,
          },
        ]);
      }
      if (layout === 'A4') {
        this.debug(
          'Set nft components for ',
          this.$store.getters.currentLayout
        );
        this.$store.commit('setComponents', [...CardDesign.components]);
      }
    },
    isCurrentLayout(layout) {
      return layout === this.currentLayout;
    },
  },
  computed: {
    ...mapGetters(['adminEmail']),
    currentLayout() {
      return this.$store.getters.currentLayout;
    },
    layouts() {
      if (
        this.adminEmail &&
        this.customTemplate[this.getEmail(this.adminEmail).domain]
      ) {
        return ['A4', 'Badge', 'NFT'];
      } else {
        return ['A4', 'Badge'];
      }
    },
  },
};
</script>

<style scoped>
.layout-name {
  font-size: 12px;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
</style>
