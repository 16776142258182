<template>
  <v-container class="create-template-view">
    <TemplateEditor> </TemplateEditor>
  </v-container>
</template>

<script>
import TemplateEditor from '../../components/TemplateEditor';

export default {
  components: {
    TemplateEditor,
  },
};
</script>

<style lang="scss">
.create-template-view {
  max-width: unset;
  width: 100vw;
  position: relative;
  margin: 0;
  margin-left: -50vw;
  left: 50%;
}
</style>
