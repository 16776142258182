<template>
  <svg viewBox="0 0 100 70.71" style="overflow: visible !important">
    <rect
      width="100%"
      height="100%"
      :fill="background"
      stroke-width="0.5"
      :stroke="highlighted()"
    />
    <text x="44" y="39" fill="#e2e2e2" font-size="0.6rem" font-weight="normal">
      A4
    </text>
  </svg>
</template>

<script>
export default {
  props: ['highlight', 'background'],
  methods: {
    highlighted() {
      return this.highlight ? '#009fb1' : '#e2e2e2';
    },
  },
};
</script>
